
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';
*{
  box-sizing: border-box;
    padding: 0;
    margin: 0;
}
body{
    background-color: #000;
    margin: 0;
}
body::-webkit-scrollbar{
  width: 5px;
  height: 8px;
  background-color: #1B1B1C;
}
body::-webkit-scrollbar-thumb{
  background-color: #FF4100;
  border-radius: 8px;
}
.wrap-related-anime::-webkit-scrollbar{
  width: 5px;
  height: 8px;
  background-color: #1B1B1C;
}
.wrap-related-anime::-webkit-scrollbar-thumb{
  background-color: #FF4100;
  border-radius: 8px;
}
h1,p,a,h2,h3,li,label{
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: normal;
    text-decoration: none;
}
nav{
    display: flex;
    position: fixed;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    justify-content: space-between;
    align-items: center;
    z-index: 999;
}
.s1{
    margin-top: 180px;
}
.container{
    width: 100%;
    max-width: 1440px;
    padding: 0px 40px;
    margin: 0px auto;
}
.homepage{
    position: relative;
}
.nav-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.wrap-logo{
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;

}
.logo{
    margin-right: 30px;
}
.logo img{
    width: 125px;
}
.wrap-link a{
    margin-right: 24px;
    text-decoration: none;
    transition: ease-in-out 0.3s;
}
.wrap-link a:hover{
    color: #FF4100;
}
.wrap-link a:last-child{
    margin-right: 0px;
}
.wrap-user-icon{
    display: flex;
}
.wrap-user-icon div{
    margin-right: 45px;
}
.wrap-user-icon div:last-child{
    margin-right: 0px;
}
.wrap-most-popular{
    display: block;
    padding: 26px 0px;
}
.wrap-most-popular h2{
    margin-bottom: 40px;
}
.wrap-most-popular a{
    margin-right: 39px;
}
.wrap-most-popular a:last-child{
    margin-right: 0px;
}
.most-popular{
    display: flex;
    overflow: hidden;
}
.most-popular div{
    margin-right: 40px;
}
.most-popular div:last-child{
    margin-right: 0;
}
.img-anime{
    width: 100%;
    height: 412px;
    object-fit: cover;
    border-radius: 8px;
}
.bg,.bg-slider{
    width: 100%;
    background-color: #1B1B1C;
    padding: 20px 0px;
    margin-bottom: 64px;
}
.bg-nav{
  width: 100%;
  background-color: #1B1B1C;
  padding: 20px 0px;
}

.most-popular-slider {
    position: relative;
  }
  
  .custom-arrow {
    position: absolute;
    top: 40%;
    width: 45px;
    height: 45px;
    background: rgba(27, 27, 28, 0.5);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 100;
  }
  
  .custom-arrow-prev {
    left: 2%;
    transform: translateY(-50%);
  }
  
  .custom-arrow-next {
    right: 2%;
    transform: translateY(-50%);
  }
  
  .arrow-prev::before,
  .arrow-next::before {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    border: 2px solid white;
    border-width: 2px 2px 0 0;
    transition: 0.3s;
  }
  .custom-arrow:hover{
    background: #FF4100;
  }
  .custom-arrow:hover .arrow-next::before{
    border-color: #1B1B1C;
  }
  .custom-arrow:hover .arrow-prev::before{
    border-color: #1B1B1C;
  }
  .arrow-prev::before {
    border-color: white;
    transform: rotate(-135deg);
    margin-left:9px;
  }
  
  .arrow-next::before {
    border-color: white;
    transform: rotate(40deg);
    margin-right:9px;
  }
  
  .most-popular-slider .slick-slide img {
    width: 100%;
    height: 412px;
    object-fit: cover;
  }
  .slick-slide img{
    margin-bottom: 21px;
  }
  .wrap-anime {
    width: 100%;
    position: relative;
    padding: 10px;
    box-sizing: border-box;
  }
  
  .s1 {
    padding: 20px 0;
  }
  
  h2 {
    color: #fff;
    margin-bottom: 20px;
  }
  
  .search-modal-content {
    background: #1B1B1C;
    top: 12%;
    right: 12%;
    position: fixed;
    padding: 20px;
    border-radius: 8px;
    border: 1px solid #FF4100;
    z-index: 1000;
    width: 400px;
    height: 650px;
    max-width: 90%; 
    overflow: auto;
  }
  .search-modal-content::-webkit-scrollbar{
    width: 5px;
    height: 8px;
    background-color: #1B1B1C;
  }
  .search-modal-content::-webkit-scrollbar-thumb{
    background-color: #FF4100;
    border-radius: 8px;
  }
  .close-button {
    background: none;
    border: none;
    font-size: 16px;
    padding: 0px;
    margin: 0px;
    cursor: pointer;
    float: right;
    color: #fff;
  }
  
  .search-results {
    margin-top: 20px;
  }
  
  .search{
    cursor: pointer;
  }
  .block-sticky{
    position: sticky;
    top: 0;
    background-color: #1B1B1C;
    padding: 20px 0px;
  }
  .search-result-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .search-result-item img {
    margin-right: 10px;
    border-radius: 4px;
    object-fit: cover;
  }
  input[type="text"]{
    position: sticky;
    top: 0;
  }
  input[type="text"] {
    width: calc(100% - 20px);
    padding: 10px;
    margin-top: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  button {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #1B1B1C;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
  }
  .slider {
    position: relative;
    margin-top: 127px;
    width: 100%;
    height: 75vh;
  }
  
  .slide {
    position: absolute;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    transition: opacity 0.5s ease-in-out;
    opacity: 0;
  }


  /*Facebook and Google*/
  .google-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    border: 1px solid #ddd;
    color: #444;
    font-size: 14px;
    font-weight: bold;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    margin: 10px 0;
    width: 100%;
    margin-top: 20px;
  }

  .auth-options{
    display: flex;
    gap: 15px;
  }
  .google-btn:hover {
    background-color: #f5f5f5;
  }
  
  .google-btn:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
  
  .facebook-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #4267B2;
    color: white;
    font-size: 14px;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    margin: 10px 0;
    width: 100%;
    margin-bottom: 20px;
  }
  
  .facebook-btn:hover {
    background-color: #365899;
  }
  
  .facebook-btn:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }

  @keyframes slideDown {
    0% {
      object-position: top;
    }
    50% {
      object-position: bottom;
    }
    100% {
      object-position: top;
    }
  }
  .slide img{
    position: absolute;
    top: 0;
    left: auto;
    right: 0;
    bottom: 0;
    background-position: top;
    background-size: contain;
    background-repeat: no-repeat;
    width: 68%;
    object-fit: cover;
    object-position: top;
    height: 75vh;
    max-width: 1440px;
    margin: auto;
    animation: slideDown 30s ease-in-out infinite;
  }

  
  .gradient-overlay-bottom {
    position: absolute;
    bottom: 0;
    top: 0;
    width: 100%;
    height: 85%;
    background:linear-gradient(90deg, rgba(92, 50, 92, 0) 30%, rgba(8, 8, 8, 0.784) 92%, rgba(8, 8, 8, 0.9) 100%), linear-gradient(112deg, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0.145) 100%), linear-gradient(90deg, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0.82) 17%, rgba(0, 0, 0, 0) 34%, rgba(0, 0, 0, 0) 80%), linear-gradient(198deg, rgba(0, 0, 0, 0.718) 0%, rgba(255, 255, 255, 0) 15%);
    z-index: -1;
    animation: slideDown 30s ease-in-out infinite;
  }
  
  .gradient-overlay-left,
  .gradient-overlay-right {
    position: absolute;
    top: 0;
    width: 70%;
    height: 100%;
    z-index: 2;
    pointer-events: none;
  }
  .gradient-overlay-left{
    width: 60%!important;
  }
  
  .gradient-overlay-left {
    left: 0;
  }
  
  .gradient-overlay-right {
    right: 0;
    background:linear-gradient(14deg, rgba(92, 50, 92, 0) 30%, rgba(8, 8, 8, 0.784) 92%, rgba(8, 8, 8, 0.9) 100%), linear-gradient(112deg, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0.145) 100%), linear-gradient(90deg, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0.82) 17%, rgba(0, 0, 0, 0) 34%, rgba(0, 0, 0, 0) 80%), linear-gradient(198deg, rgba(0, 0, 0, 0.718) 0%, rgba(255, 255, 255, 0) 15%)
  }
  .slide.active {
    opacity: 1;
  }
  
  .slide-content {
    position: absolute;
    left: 8%;
    top: 50%;
    transform: translateY(-50%);
    color: white;
    max-width: 700px;
    z-index: 50;
  }
  
  .slide-content h2 {
    font-size: 2em;
    margin-bottom: 10px;
  }
  
  .slide-content p {
    margin-bottom: 20px;
    max-width: 500px;
  }
  
  .slide-buttons {
    display: flex;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
  }
  .item-btn{
    justify-content: center;
    height: 50px;
  }
  .watch-now {
    display: flex;
    padding: 10px 20px;
    font-size: 18px;
    align-items: center;
    border-radius: 8px;
    border: 1px solid transparent;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    height: 50px;
  }
  .watch-now:hover path{
    fill:white;
  }
  .watch-now {
    background-color: #ff5722;
    color: #000;
  }
  .watch-now:hover .tv{
    opacity: 0;
  }
  .watch-now:hover .tv_hover{
    opacity: 1;
  }
  .watch-now:hover{
    background-color: #1B1B1C;
    color: #fff;
  }
  .details {
    background-color: transparent;
    color: #fff;
    border: 2px solid #fff;
  }
  
  .prev-button, .next-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    font-size: 2em;
    cursor: pointer;
    z-index: 10;
  }
  
  .prev-button {
    left: 10px;
  }
  
  .next-button {
    right: 10px;
  }
  .slick-slider{
    height: 550px;
  }
  .slide-content p {
    display: -webkit-box;
    -webkit-line-clamp: 7;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .hover-anime h3 {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .footer{
    background-color: #1B1B1C;
    padding: 40px;
  }
  .wrap-footer{
    display: flex;
    justify-content: space-between;
    padding-bottom: 40px;
  }
  .wrap-logo-and-content img{
    margin-bottom: 45px;
  }
  .wrap-logo-and-content p{
    margin-bottom: 16px;
    max-width: 600px;
  }
  .wrap-footer-link{
    display: flex;
    gap: 16px;
    margin-bottom: 40px;
    align-items: flex-start;
  }
  .wrap-footer-link a{
    transition: 0.3s ease-in-out;
  }
  .wrap-footer-link a:hover{
    color: #FF4100;
  }
  .hover-anime{
    position: absolute;
    padding: 30px;
    opacity: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: hsl(240, 2%, 11%, 0.5);
    top: 0;
    right: 0;
    left: 0 ;
    height:423px;
    width: 100%;
    z-index: 102;
  }
  .hover-anime p{
    margin-bottom: 32px;
  }
  .hover-anime h3{
    margin-bottom: 32px;
  }
  .wrap-anime:hover .hover-anime{
    opacity: 1;
  }
  .anime-description {
    width: 100%;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    margin-bottom: 50px!important;
  }
  .tv{
    position: relative!important;
    width: 29px!important;
    height: 25px!important;
    transition: 0.3s ease-in-out;
    margin-bottom: 0px!important;
  }
  .tv_hover{
    width: 29px!important;
    height: 25px!important;
    position: absolute; 
    opacity: 0;
    margin-bottom: 0px!important;
    transition: 0.3s ease-in-out;
  }
  .wrap-img-btn{
    margin-left: 11px;
    display: flex;
    position: relative;
  }
  .wrap-page-anime{
    padding-top: 130px;
    padding-bottom: 60px;
  }
  .wrap-content-anime-page{
    display: flex;
    margin-bottom: 40px;
    position: relative;
    justify-content: space-between;
    z-index: 5;
  }
  .wrap-img-page{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 40px;
  }
  .genres{
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  .genres h3{
    margin-right: 16px;
  }
  .item-genre{
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
  }
  .genres-title{
    font-size: 24px;
    margin-bottom: 40px;
  }
  .item-genre p{
    padding: 10px;
    border: 1px solid #FF4100;
  }
  .title-home,.title-contact,.genres-title,.top-anime-title,.upcoming-title{
    display: none;
  }
  .top-anime-title{
    font-size: 24px;
    margin-bottom: 40px;
    color: #FF4100;
    text-transform: uppercase;
  }
  .wrap-heading-and-description{
    max-width: 600px;
    margin-right: 50px;
  }
  .wrap-heading-and-description h1{
    margin-bottom: 20px;
    font-size: 24px;
  }
  .img-page{
    margin-bottom: 30px;
  }
  .bg-img-page-anime{
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
    z-index: -1;
  }
  .bg-item-page-blur{
    width: 100%;
    height: 100%;
    background-color: hsl(240, 2%, 11%,0.8);
    position: absolute;
    top: 0;
    backdrop-filter: blur(4px);
    z-index: -1;
  }
  .wrap-info-anime{
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 514px;
    height: 475px;
    background-color: hsl(240, 2%, 11%,0.8);
    padding: 65px 42px;
    border-radius: 16px;
  }
.wrap-content-related{
  display: flex;
}
.wrap-content-related-title{
  width: 100%;
  max-width: 226px;
  margin-right: 24px;
}
.wrap-related-anime{
  padding: 10px 20px;
  overflow: auto;
  margin-bottom: 25px;
  background-color:#1B1B1C ;
}
.center-btn{
  align-items: center;
}
.related-anime-item{
  display: flex;
  gap: 20px;
}
.bg-page-related{
  margin-top: 40px;
  margin-bottom: 0px!important;
}
.wrap-screenshots{
  overflow: hidden;
}
.screenshot-img{
  width: 250px;
  height: 200px;
  padding: 20px 0px;
}
.screenshots{
  display: flex;
  gap: 16px;
  overflow: auto;
  margin-bottom: 60px;
}
.screenshots::-webkit-scrollbar{
  width: 5px;
  height: 8px;
  background-color: #1B1B1C;
}
.screenshots::-webkit-scrollbar-thumb{
  background-color: #FF4100;
  border-radius: 8px;
}
.related-content{
  overflow: hidden;
  position: relative;
  padding: 40px 0px;
  background-color:#1B1B1C;
}
.related-conteiner{
  display: flex;
  gap: 24px;
  overflow: auto;
  padding-bottom: 20px;
}
.related-conteiner::-webkit-scrollbar{
  width: 5px;
  height: 8px;
  background-color: #1B1B1C;
}
.related-conteiner::-webkit-scrollbar-thumb{
  background-color: #FF4100;
  border-radius: 8px;
}
.wrap-related-items{
  display: flex;
  max-width: 226px;
  gap: 24px;
}
.related-item{
  max-width: 230px;
}
.related-content h3{
  margin-bottom: 24px;
}
.wrap-video{
  margin-bottom: 60px;
}
.screenshots{
  cursor: pointer;
}
.img-page-bg{
  position: absolute;
  width: 100%;
  top: 13%;
  height: 70%;
  object-fit: cover;
  object-position: top;
  filter: blur(2px);
  z-index: -1;
  animation: slideDown 30s ease-in-out infinite;
}
.wrap-items-page{
  background-color: #00000080;
  padding-top: 40px;
}
.wrap-bg-img-page-anime{
  position: relative;
}
.wrap-dmca{
  padding-top: 160px;
}
.wrap-items-dmca{
  display: flex;
  flex-direction: column;
  gap:24px;
}
.wrap-items-dmca ol{
  padding-left: 40px;
}
.wrap-items-dmca li{
  margin-bottom: 16px;
}
.wrap-items-dmca li:last-child{
  margin-bottom: 0px;
}
.wrap-form{
  display: flex;
  width: 100%;
  flex-direction: column;
  gap:24px;
  max-width: 650px;
  margin-bottom: 60px;
}
.wrap-form label{
  display: flex;
  flex-direction: column;
}
.wrap-form input{
  width: 100%;
  height: 56px;
  margin-top: 0px;
  border-radius: 16px;
  padding: 16px;
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.wrap-form textarea{
  border-radius: 16px;
  padding: 16px;
  height: 150px;
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.required{
  color: #FF4100;
}
.wrap-text-label{
  display: flex;
  gap: 4px;
  margin-bottom: 10px;
}
.center{
  justify-content: center;
}
.answer-email{
  background-color: #FF4100;
  border-radius: 16px;
  margin-bottom: 40px;
  padding: 16px;
  max-width: 650px;
  display: flex;
  justify-content: center;
}
.answer-email p{
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #000;
}


.filter-page {
  padding: 20px;
}

.filter-box {
  background-color: #333;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.filter-group {
  margin-bottom: 10px;
}

.genres-container {
  display: flex;
  flex-wrap: wrap;
}

.genres-container button {
  margin: 5px;
  padding: 10px;
  border-radius: 5px;
  background-color: #444;
  color: #fff;
  border: none;
}

.genres-container button.active {
  background-color: #f5a623;
}

.filter-results {
  margin-top: 20px;
}

.results-grid {
  display: flex;
  flex-wrap: wrap;
}

.result-item {
  margin: 10px;
}

.result-item img {
  width: 150px;
  height: 220px;
  object-fit: cover;
}
.wrap-grid-anime{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.wrap-genres-anime{
  margin-top: 180px;
}
.filter-container{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 16px;
  height: 250px;
  overflow: auto;
  margin-bottom: 40px;
  padding-right: 20px;
}
.filter-container::-webkit-scrollbar{
  width: 5px;
  height: 8px;
  background-color: #1B1B1C;
}
.filter-container::-webkit-scrollbar-thumb{
  background-color: #FF4100;
  border-radius: 8px;
}
.filter-container div{
  position: relative;
text-align: center;
border: 1px solid #FF4100;
color: white;
font-size: 14px;
font-family: "Poppins", sans-serif;
font-weight: 400;
font-style: normal;
border-radius: 5px;
cursor: pointer;
}
.filter-container label:hover{
  background-color: #FF4100;
  color: #1B1B1C;
  font-weight: bold;
}
.filter-container label{
  width: 100%;
  display: block;
  padding: 10px 10px;
  cursor: pointer;
}
input[type="checkbox"]{
  position: absolute;
  width: 100%;
  opacity: 0;
  display: none;
} 
input[type="checkbox"]:checked  ~ label{
  background-color: #FF4100;
  color: #1B1B1C;
  font-weight: bold;
}

.wrap-sort{
  margin-bottom: 40px;
  display: flex;
  gap: 24px;
}
.wrap-sort label{
  display: flex;
  gap: 5px; 
  align-items: center;
}
.select-wrapper {
  position: relative;
  display: inline-block;
  width: 260px;
}

.select-wrapper select {
  appearance: none;
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background: #fff;
  font-size: 16px;
  cursor: pointer;
  outline: none;
}

.select-wrapper::after {
  content: ' ';
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #666;
  pointer-events: none;
}

.select-wrapper select:focus {
  border-color: #007bff;
}

.label-sort p{
  margin-right: 10px;
}
.upcoming-s{
  margin-top: 180px;
}
.wrap-items-upcoming{
  display: grid;
  gap: 24px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.info-upcoming p{
  margin-bottom: 12px;
}
.info-upcoming h3{
  margin-bottom: 12px;
}
.info-upcoming p:last-child{
  margin-bottom: 0;
}
.top-s{
  margin-top: 180px;
}
.wrap-items-top{
  display: grid;
  gap: 24px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
/* Основные стили для бургер-меню */
.burger-menu {
  display: none; 
  flex-direction: column;
  cursor: pointer;
  padding: 10px;
}

.burger-line {
  width: 25px;
  height: 2px;
  background-color: #fff;
  margin: 3px 0;
  transition: 0.3s;
}

.burger-line.open {
  background-color: #fff;
}

.nav-links {
  display: flex;
  gap: 15px;
}

.mobile-menu {
  display: none;
  position: fixed;
  top: 16%;
  left: 3%;
  background-color: #1b1b1c;
  border-radius: 16px;
  box-shadow: 0 4px 8px #0000001a;
  display: none;
  left: 3%;
  padding: 15px;
  position: fixed;
  top: 16%;
  width: 95%;
  z-index: 5555;
}

.mobile-menu a {
  display: block;
  padding: 10px 0;
}

.mobile-menu {
  display: flex;
  justify-content: space-evenly;
  padding: 20px;
}
.section-terms-of-service{
  margin-top: 180px;
}

.user {
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 100px;
}
.language-selector{
  display: none;
}
.user-avatar {
  width: 36px;
  height: 36px;
  cursor: pointer;
  object-fit: contain;
}
.center-block{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.dropdown-content {
  display: none;
  position: absolute;
  width: 256px;
  gap:15px;
  flex-direction: column;
  top: 50px;
  right: 0;
  background-color: #1B1B1C;
  border: 1px solid #FF4100;
  padding: 10px;
  border-radius: 8px;
  z-index: 1000;
}

.dropdown .dropdown-content {
  display: flex;
}
.center-btn{
  text-align: center;
  justify-content: center;
}
.dropdown-content a:hover{
  color: #FF4100;
}
.dropdown-content p{
  font-size: 25px;
}
.dropdown-content a{
  font-size: 18px;
}
.avatar-selection {
  display: flex;
  flex-wrap: wrap;
  max-width: 800px;
  gap: 10px;
  margin-top: 20px;
  margin-bottom: 50px;
}

.avatar {
  width: 50px;
  height: 50px;
  cursor: pointer;
  border: 2px solid transparent;
  border-radius: 50%;
  object-fit: contain;
}

.avatar.selected {
  border-color: #FF4100; /* Зеленая рамка для выбранной аватарки */
}
.profile{
margin-top: 150px;
}


.end-anime{
  color: #fff;
  width: 100%;
  font-size: 16px;
  padding: 20px;
}
.search-result-link h3{
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.yarl__navigation_prev,.yarl__navigation_next{
  width: 5%;
}
.yarl__navigation_next{
  padding: 15px!important;
  right: 2%!important;
  background-color: #1b1b1c91!important;
}
.yarl__navigation_prev{
  padding: 15px!important;
  background-color: #1b1b1c91!important;
  left: 2%!important;
}
.yarl__navigation_next:hover{
  background-color: #FF4100!important;
}
.yarl__navigation_prev:hover{
  background-color: #FF4100!important;
}
.yarl__navigation_prev:hover .yarl__icon{
  color: #1B1B1C;
}
.yarl__navigation_next:hover .yarl__icon{
  color: #1B1B1C;
}
.yarl__icon{
  color: #FF4100;
}
.tabs{
  display: flex;
  gap:15px;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
}
.tab-content{
padding-left: 50px;
padding-right: 50px;
min-height: 500px;
}
.tabs button{
  background: transparent;
  border-bottom:1px solid #ffffff7c;
  width: 250px;
  font-size: 22px;
}
.tabs button:hover{
  border-bottom:1px solid #FF4100;
}
.change-passw{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
  gap:15px;
  width: 250px;
  margin: 0 auto;
}
.wrap-form-password {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 24px;
}
.history-tab{
  margin-bottom: 50px;
}
#kodik-player{
  display: none;
}
.wrap-form-password input{
  width: 100%;
  height: 56px;
  margin-top: 0px;
  border-radius: 16px;
  padding: 16px;
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.comment-item{
  display: flex;
  gap: 15px;
}
.comment-body{
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.comment-avatar{
  width: 65px;
  height: 65px;
  border-radius: 50%;
}
.comment-avatar img{
width: 100%;
border-radius: 50%;
}
.comment-form{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 25px;
}
.comment-container h3{
  margin-bottom: 17px;
  font-size: 22px;
}
.comment-form .watch-now{
  margin-top: 28px;
  width: 150px;
  justify-content: center;
}
.comment-form textarea{
  width: 100%;
  border-radius: 8px;
  height: 138px;
  padding: 20px;
}
.comment-form input{
  width: 100%;
  height: 56px;
  margin-top: 0px;
  border-radius: 16px;
  padding: 16px;
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.pt-160px{
  padding-top: 160px;
}
.comment-container{display:none;}
.user{display:none;}
.btn-plan-to-watch{display:none;}
@media all and (max-width:950px) {
  .container{
    padding: 0px 16px;
  }
  .footer{
    padding: 16px 0px;
  }
  .wrap-grid-anime{
    grid-template-columns: 1fr 1fr;
  }
  .filter-container{
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  .wrap-logo{
    justify-content: space-between;
    width: 100%;
  }
  .burger-menu {
    display: flex;
  }
  .tab-content{
    padding-left: 16px;
    padding-right: 16px;
    }
  .wrap-link {
    display: none;
  }
  .wrap-sort{
    flex-direction: column;
  }
  .wrap-items-upcoming{
    grid-template-columns: 1fr 1fr;
  }
  .wrap-items-top{
    grid-template-columns: 1fr 1fr;
  }
  .wrap-content-anime-page{
    flex-direction: column;
    justify-content: center;
  }
  .wrap-heading-and-description,.wrap-img-page{
    margin-right: 0px;
    margin-bottom: 40px;
    max-width: 100%;
  }
  .wrap-info-anime{
    width: 100%;
    height: auto;
  }
  .wrap-footer{
    flex-direction: column;
  }
  .wrap-logo-and-content{
    margin-bottom: 40px;
  }
}
@media screen and (max-width:760px) {
  .user{
    display: none;
  }
  .wrap-user-icon div{
    margin-right: 16px;
  }
  .tab-content{
    overflow: hidden;
    min-height: auto;
  }
  .tabs{
    flex-direction: column;
  }
  .avatar-selection{
    gap:0;
    justify-content: center;
    align-items: center;
  }
  .mobile-menu{
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .bg,.bg-slider{
    margin-bottom: 30px;
  }
  .wrap-items-top,.wrap-items-upcoming{
    gap: 16px;
  }
  .wrap-items-upcoming{
    grid-template-columns: 1fr  ;
  }
  .img-anime{
    height: auto;
  }
  .section-terms-of-service p{
    font-size: 14px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .filter-container{
    grid-template-columns: 1fr 1fr;
    height: 234px;
  }
  .wrap-anime h3{
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .search-modal-content{
    top: 16%;
    right: 5%;
    height: 500px;
  }
}